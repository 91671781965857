// user types
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const AUTO_SIGN_IN = 'AUTO_SIGN_IN';
export const SIGN_IN_WITH_FACEBOOK = 'SIGN_IN_WITH_FACEBOOK';
export const SIGN_IN_WITH_TWITTER = 'SIGN_IN_WITH_TWITTER';
export const SIGN_IN_WITH_GOOGLE ='SIGN_IN_WITH_GOOGLE';
export const SIGN_IN_WITH_LINKEDIN = 'SIGN_IN_WITH_LINKEDIN';
export const SEND_RESET_PASSWORD_MAIL ='SEND_RESET_PASSWORD_MAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const FIND_USER = "FIND_USER";
export const DELETE_USER = "DELETE_USER";
export const FIND_USERS = "FIND_USERS";
export const GET_USERS = "GET_USERS";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

export const ADD_IDEA = "ADD_IDEA";
export const FIND_IDEA = "FIND_IDEA";
export const FIND_IDEAS = "FIND_IDEAS";
export const UPDATE_IDEA = "UPDATE_IDEA";
export const DELETE_IDEA = "DELETE_IDEA";

export const NOTIFICATIONS_PERMISSION = "NOTIFICATIONS_PERMISSION";
export const FIND_NOTIFICATIONS = "FIND_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const FIND_CONNECTIONS = "FIND_CONNECTIONS";
export const UPDATE_CONNECTION = "UPDATE_CONNECTION";
export const ADD_CONNECTION = "ADD_CONNECTION";
export const UPDATE_LAST_MESSAGE = "UPDATE_LAST_MESSAGE";