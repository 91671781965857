import React, {PureComponent} from "react";
import Page from "../page";
import {Avatar, Box, Button, Container, Divider, Grid} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    askForPermissioToReceiveNotifications,
    findAllNotifications,
    updateNotificationMarkAllAsReak,
    updateNotificationMarkAsReak
} from "../../store/actions/notification_action";
import {findUserDetails} from "../../store/actions/user_action";
import {firebaseNotifications} from "../../libs/config";

/* you can see all your notifications */
class Notification extends PureComponent {
    state = {
        notifications: []
    };
    notificationObserver = null;

    componentWillMount() {
        {
            console.log('prop');
            console.log(this.props)
        }
        {
            console.log('state');
            console.log(this.state)}
        if (this.state.notifications.length  == 0 && this.props.user) {
            let type = "";
            this.notificationObserver = firebaseNotifications
                .where("to", "==", this.props.user.email)
                .orderBy("createdAt", "desc")
                .onSnapshot(async (querySnapshot) => {
                    await querySnapshot.docChanges().forEach((newNotification) => {
                        type = newNotification.type;

                        const notification = newNotification.doc.data();
                        notification.id = newNotification.doc.id;
                        this.state.notifications.push(notification);

                        console.log('addNoti');
                        console.log( this.state.notifications);
                    });
                    this.forceUpdate()
                });
        }
    }



    render() {
        return (
            <Page className="notification">
                <Container>
                    <Grid container justify="space-between" alignItems="flex-start">
                        <Grid item>
                            <Box fontWeight="fontWeightBold" fontSize="1.2rem">
                                Notification
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button
                                className="no-outline"
                                color="primary"
                                onClick={event => {
                                    event.preventDefault();
                                    this.props.history.push("/account-settings/notification");
                                }}
                            >
                                Notification settings
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container className="message-box">
                        {console.log('in reder')}
                        {/*{console.log(this.props)}*/}
                        {console.log(this.state)}
                        {this.state.notifications.length > 0 ? (
                            this.state.notifications.map(notification => (
                                <Grid item key={notification.id} xs={12}>
                                    <Box className="message-text">
                                        <Avatar src={notification.avatar}>
                                            <AccountCircleIcon/>
                                        </Avatar>
                                        <span className="p-2">{notification.message}</span>
                                    </Box>
                                    <Divider/>
                                </Grid>
                            ))
                        ) : (
                            <Box>You do not have any notification</Box>
                        )}
                    </Grid>
                </Container>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        Notification: state.Notification,
        noti: state,
        user: state.User.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            askForPermissioToReceiveNotifications,
            findAllNotifications,
            updateNotificationMarkAsReak,
            updateNotificationMarkAllAsReak,
            findUserDetails,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notification));
